const getDefaultImageUrls = (imageConfigurations) => {
  const imageUrls = {
    42: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=28,quality=10,width=42/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    201: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=134,quality=70,width=201/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    220: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=146,quality=70,width=220/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    312: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=208,quality=70,width=312/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    321: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=214,quality=70,width=321/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    330: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=220,quality=70,width=330/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    335: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=223,quality=70,width=335/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    336: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=224,quality=70,width=336/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    375: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=250,quality=70,width=375/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    390: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=260,quality=70,width=390/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    402: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=268,quality=70,width=402/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    414: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=276,quality=70,width=414/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    440: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=293,quality=70,width=440/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    469: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=313,quality=70,width=469/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    502: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=335,quality=70,width=502/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    570: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=380,quality=70,width=570/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    603: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=402,quality=70,width=603/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    621: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=414,quality=70,width=621/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    624: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=416,quality=70,width=624/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    642: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=428,quality=70,width=642/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    660: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=440,quality=70,width=660/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    670: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=446,quality=70,width=670/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    672: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=448,quality=70,width=672/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    720: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=480,quality=70,width=720/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
    1242: 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=828,quality=70,width=1242/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
  };
  return imageConfigurations.map(
    (imageConfiguration) => imageUrls[imageConfiguration.width],
  );
};

export default getDefaultImageUrls;
