import getDefaultImageUrls from './getDefaultImageUrls';
import getSourceSet from './getSourceSet';

const getProxiedImages = (variables, originalImageUrls) => {
  const defaultImageUrls = getDefaultImageUrls(variables.imageConfigurations);

  const imageUrls = originalImageUrls[0] ? originalImageUrls : defaultImageUrls;

  const sourceSet = getSourceSet(variables.imageConfigurations, imageUrls);

  return { imageUrls, srcSet: sourceSet };
};

export default getProxiedImages;
